.otpVerficationHeadDiv {
    margin-left: 13px;
    &_txt {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
    }
  }
  .otpDiv {
      margin-top: 24px;
      margin-bottom: 32px;
      &_emailDiv {
          margin-bottom: 32px;
      }
  }
  
  .otpScreenbackImg {
      cursor: pointer;
  }

.otpTimer {
    margin-top: 8px;
    &_resend {
        font-weight: 700;
        font-size: 14px;
        line-height: 160%;
        color: #0B7764;
        cursor: pointer;
    }
}


.noAccessContained {
    display: flex;
    align-items: center;
    justify-content: center;

    &_txtSubDiv1 {
        width: 100%;
        align-items: center;
        margin-bottom: 8px;
        text-align: center;
        margin-top: 14px;
    }
    &_txtSubDiv {
        width: 100%;
        align-items: center;
        margin-bottom: 8px;
        text-align: center;
    }
    &_txtHead {
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        color: #040E0C;
    }
    &_txtSub {
        font-weight: 400;
        font-size: 14px;
        color: #4F5655;
    }
    &_btnDiv {
        margin-top: 40px;
        &_btn {
            background-color: #1D2624 !important; 
            color: #FFF !important;
            height: 40px;
            border-radius: 4px;
            padding: 8px 36px;
        }
        &_btn:hover {
            background-color: #1D2624 !important;
            color: #FFF !important;
            height: 40px;
            border-radius: 4px;
            padding: 8px 36px;
        }
        &_btn:focus {
            background-color: #1D2624 !important;
            color: #FFF !important;
            height: 40px;
            border-radius: 4px;
            padding: 8px 36px;
        }
    }
}

.otpVerify {
    background-color: #4F5655 !important;
}
@primary-color: #278977;