.buckets .ant-checkbox-group-item {
    margin-right: 0px;
    display: flex;
    padding-bottom: 10px;
}

.buckets {
    max-height: 20rem;
    overflow-y: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
.buckets::-webkit-scrollbar { 
display: none;  /* Safari and Chrome */
}

.popoverBucket {
    margin-left: 10px;
    color: #4F5655;
}

.searchText.mobile .ant-input-affix-wrapper, .searchText.mobile .ant-input-group-addon button{
    height: 40px;
}

.searchText .ant-btn  {
    border-left: none;
    /* padding-bottom: 10px !important; */
}

.searchText .ant-input-suffix {
    margin-bottom: 3px;
}

.productsCount {
    color: #4F5655;
    padding-top: 10px;
}

.filterChips {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: #E6E7E7;
    margin-right: 10px;
    margin-top: 10px;
}

.filterChips .ant-badge {
    margin-left: 8px;
    padding-top: 4px;
}

.closeIcon {
    padding: 0 5px;
}

#horizontalFilter {
    margin: 20px 0 0 0;
    background: white;
    padding: 10px 0;
}

.filterSvg {
    padding: 3px;
    cursor: pointer;
    padding-top: 8px;
}

.stickyHorizontal {
    position: sticky;
    z-index: 200;
    background-color: white;
    padding-left: 10px;
}