.alignCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  position: absolute;
  width: 50%;
  /* height: 65%; */
  left: 25%;
  top: 15%;
  padding-top: 2em;
  padding-bottom: 2em;

  background: #f0f8f6;
  border-radius: 12px;
}

.catButton {
  position: absolute;
  top: 80%;

  /* Neutral/Grey/Grey 7 */

  background: black;
  color: white;
  border-radius: 4px;
}

@media only screen and (max-width: 700px) {
  .mainContainer {
    position: absolute;
    width: 100%;
    /* height: 85%; */
    left: 0%;
    top: 10%;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;

    background: #f0f8f6;
    border-radius: 12px;
    
  }
}
