@mobile: ~"only screen and (max-width: 700px)";
@ipad: ~"only screen and (max-width: 1000px)";

.site-layout-background {
  @media @mobile {
    // padding: 10px !important;
    margin: 0 !important;
  }
}
.shortlisted {
  background: rgba(255, 255, 255);
  padding: 0.3rem 0.5rem;
  top: 0;
  right: 0;
  margin: 1rem;
  position: absolute;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 7rem;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}
.shortlisted-icon {
  color: @primary-color;
}
.shortlist-icon {
  color: #fff;
}
.shortlist-on-hover {
  display: none;
  // background: rgba(0, 0, 0, 0.5);

  // border-radius: 5%;
  // color: #fff;
  // top: 0;
  // right: 0;
  // margin: 1rem;
  // position: absolute;
  // transition: 1s;
}
.delete-shortlist {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 0.3rem;
  top: 0;
  right: 0;
  margin: 1rem;
  position: absolute;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.wishlistIcon {
  z-index: 100;
}
.shortlist {
  background: #ffffff;
  color: #fff;
  // padding: 0.3rem 0.5rem;
  top: 0;
  right: 0;
  margin: 0.5rem;
  position: absolute;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 2rem;
  height: 2rem;
  z-index: 100;

  &.boxShadow {
    box-shadow: 0 5px 24px #e8e8e8;
  }

  span {
    background: #ffffff;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 24px #e8e8e8;
  }
}

.shortlistCol {
  // background: #040e0c63;
  color: #fff;
  // padding: 0.3rem 0.5rem;
  top: 0;
  right: 0;
  margin: 0.1rem;
  position: absolute;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 2rem;
  height: 2rem;
  z-index: 100;
  margin-right: 5px;
  margin-top: 10px;

  span {
    background: #ffffff;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0 5px 24px #e8e8e8;;
  }
}

.shortlist img,
.shortlistCol img {
  border: none;
}
.thumb {
  border-radius: 8px;
}
.thumb.selected {
  border-radius: 8px;
}
.carousel .carousel-slider {
  border-radius: 8px;
}
.menu-icon {
  @media @mobile {
    display: none;
  }
}
.share-button {
  @media @mobile {
    margin-top: 1.5rem;
  }
}
.shorlist-page-actions {
  @media @mobile {
    margin-top: 1rem;
  }
}

.send-query-actions {
  position: fixed;
  bottom: 0;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  padding: 1rem;
  border-top: 1px solid lightgray;
  width: 100%;
  left: 0;
}
.email-copy-action {
  width: 100%;
  @media @mobile {
    margin-top: 0.75rem;
  }
}
.product-row {
  @media @mobile {
    margin-top: 1rem;
  }
}
.buyer-logo {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  // @media @mobile {
  //   width: 40px;
  //   height: 40px;
  // }
}
.buyer-home-footer {
  @media @mobile {
    text-align: center;
  }
}
.end-msg {
  text-align: center;
  margin: 30px !important;
}

.product-card-send-query {
  border-radius: 8px;
  // height: 200px;
  object-fit: cover;
  // cursor: pointer;
  // border:none;
  border: 1px solid #e6e7e7;
  img {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: 1px solid #e6e7e7;
    border-bottom: 0px;
  }
}

.product-card {
  position: relative;
  z-index: 100;
}

.product-card .ant-card-body {
  padding: 10px 20px 0px 20px;
}

.cardCover .ant-image {
  width: 100%;
  cursor: pointer;
}

.textEllipsis {
  width: 100px;
}

@media only screen and (min-width: 450px) {
  .textEllipsis {
    width: 150px;
  }
}

@media only screen and (min-width: 600px) {
  .textEllipsis {
    width: 200px;
  }
}

@media only screen and (min-width: 750px) {
  .textEllipsis {
    width: 250px;
  }
}

@media only screen and (min-width: 900px) {
  .textEllipsis {
    width: 300px;
  }
}

.hoverDiv {
  display: none;
  position: absolute;
  justify-content: center;
  left: 0px;
  bottom: 4rem;
  width: 100%;
  height: 40px;
  background-color: white;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid rgb(230, 231, 231);
  cursor: pointer;
  z-index: 10;
}

.wishlistAction {
   display: flex;
   justify-content: center;
}

.shortlist.hover {
  // top: 0.2rem;
  // left: 4.5rem;
  margin: 0px;
  background: none;
  position: relative;

  img {
    border: none;
    margin-top: -10px;
  }
}

.variants {
  font-size: 10px;
}

.wishlisted {
  left: 3rem !important;
}

.product-card:hover .hoverDiv {
  display: flex;
}

.comment-box {
  @media @mobile {
    margin-top: 1rem;
  }
  @media @ipad {
    margin-top: 1rem;
  }
}
.sent-query-container {
  border-radius: "1vh";
  padding: "0vh";
  padding-bottom: 10vh;
  background: #f0f8f6;
  margin-right: 7%;
  margin-left: 7%;
  @media @mobile {
    margin-right: 0%;
    margin-left: 0%;
  }
  .sent-query-title {
    font-size: 30px;
    margin-bottom: 0;
    color: #1b6053;
    text-align: center;
    z-index: 2;
    margin-top: 3rem;
    font-family: Gilroy;

    @media @mobile {
      font-size: 24px;
    }
  }
}
.sent-query-form-container {
  background: white;
  border-radius: 1vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media @mobile {
    padding: 0.5rem;
  }
}

.sent-query-form-subtitle {
  margin-top: 0.5rem;
  text-align: center;
  color: gray;
}
.sent-query-form-title {
  font-size: 20px;
  text-align: center;

  @media @mobile {
    font-size: 16px;
  }
}
.download-ppt-icons {
  width: 60px;
  height: 60px;

  @media @mobile {
    width: 45px;
    height: 45px;
  }
}
.download-title {
  font-size: 16px;
  @media @mobile {
    font-size: 14px;
  }
}
.download-sub-title {
  font-size: 12px;
  @media @mobile {
    font-size: 10px;
  }
}
.download-menu {
  margin: 5px 15px;
  width: 400px;
  @media @mobile {
    width: 325px;
  }
}
.catlogue-actions {
  @media @mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
}
.sent-query-cancel-container {
  @media @mobile {
    padding: 10px 20px;
  }

  .title {
    font-size: 24px;
    @media @mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      font-size: 14px;
    }
  }
  .action {
    @media @mobile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  .cta {
    @media @mobile {
      width: auto;
      font-size: 14px !important;
    }
  }
}

button:disabled {
  border: 1px solid #999999;
  background-color: #cdcfce;
  color: white;
}
.canvas-360 {
  height: 70vh;
  width: 75vw;
  @media @mobile {
    height: 40vh;
    width: 80vw;
  }
}

@primary-color: #278977;