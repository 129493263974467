* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

.confirm {
  color: gray;
}

.comment-box {
  margin-top: 1.3rem;
}

.removebtn {
  box-shadow: none;
  font-size: 14px;
  cursor: pointer;
  border: none;
  float: right !important;
}

.sendbtn {
  width: auto;
  background-color: black !important;
  border: none;
  float: right !important;
}

.sendbtnleft {
  width: auto;
  background-color: white !important;
  float: left !important;
  color: black;
}
.emailbtn {
  width: 140px;
}

.sendbtn:disabled {
  background-color: #cdcfce !important;
  color: white;
}

.emailModal {
  border-radius: '8px';
  height: '250px';
  box-shadow: 'inherit';
}

.query-box {
  margin-top: 1.3em;
  height: 7em !important;
  /* min-height: 269px; */
}
@media only screen and (max-width: 400px) {
  .emailModal {
    border-radius: '8px';
    height: 400px;
    box-shadow: 'inherit';
  }
  .send-query-card-image {
    height: 9rem;
  }
  .removebtn {
    border-radius: 8px;
  }
}

@media only screen and (max-width: 500px) {
  .query-box {
    margin-top: 0.3rem;
    height: 5.25em !important;
  }

  .emailModal {
    border-radius: '8px';
    height: 400px;
    box-shadow: 'inherit';
  }
}

.stickyHeader {
  height: 4.6em;
}

.maindiv {
  padding-left: 15px !important;
  padding-right: 3em !important;
}
@media only screen and (max-width: 800px) {
  .stickyHeader {
    height: 100% !important;
  }
}

@media only screen and (max-width: 600px) {
  .maindiv {
    padding-left: 0em !important;
    padding-right: 0em !important;
    margin-left: -10px;
  }
}

.mobileQuery {
  display: flex;
  justify-content: center;
}
