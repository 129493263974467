.topBar {
    display: flex;
    align-items: center;
    // height: 72px;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #E6E7E7;
    padding: 16px 20px;
    position: absolute;
    top: 0;
    left: 0;
    &_img {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 20px;
    }
    &_txt {
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #040E0C;
    }
}

.qrPageContainer {
    margin-top: 75px;
    width: 100%;
    &_errHandle {
        width: 100%;
        text-align: center;
        &_txt1 {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
        }
        &_txt2 {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
        }
        &_refresh {
            background: #1D2624;
            border-radius: 4px;
            color: #fff;
            height: 40px;
        }
    }
    &_itemRow {
        margin: 16px 0;
    }
    &_txtSmall {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #686E6D;
        margin-bottom: 4px;
    }
    &_txtLarge {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: #1D2624;
    }
    &_txtSmall1 {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #686E6D;
        margin-bottom: 4px;
        margin-top: 16px;
    }
}

.ant-carousel .slick-prev  {
    display: block; 
    margin-left: 40px;
    z-index: 10;
}

.ant-carousel .slick-next {
    display: block; 
    margin-right: 30px;
    z-index: 10;

}
.carousalImg {
    // width: 250px;
    height: fit-content;
    max-height: 360px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: contain;
    padding: 5px;

}

.cataloguesTxt {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #040E0C;
    margin-top: 32px;
}

.cataloguesTxtSmall {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4F5655;
    // margin-top: 8px;
}


.catalogueDiv {
    margin-top: 16px;
    width: 100%;
    overflow-y: auto;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    
    &_catalogueCard {
        background: #F5F5F5;
        border-radius: 8px;
        height: 142px;
        min-width: 186px;
        max-width: 186px;
        margin-right: 12px;
        padding: 14px;
        position: relative;
        &_txtSmall {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #4F5655;
            margin-bottom: 12px;
        }
        
        &_txtLarge {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #040E0C;
            margin-bottom: 4px;
        }
        &_imageDiv {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            margin-top: 12px;
            position: absolute;
            bottom: 4px;
            &_img {
                border-radius: 4px;
                width: 48.67px;
                height: 48px;
                margin-right: 6px;
            }
        }
        }

}


.sourcewizFooter {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
   
    margin: 45px 0 45px 0;
    &_txt {
        margin-right: 6px;
    }
    &_img {
        height: 18px;
        width: 110px;
    }
}

.failedContainer{
    // display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    padding: auto;

    &_img {
        width: 120px;
        height: 120px;
        margin-bottom: 40px;
    }
    &_txtHead {
        font-weight: 700;
        font-size: 16px;
        // line-height: 150%;
        text-align: center;
        color: #040E0C;
    }
    &_txtSub {
        font-weight: 400;
        font-size: 14px;
        // line-height: 150%;
        text-align: center;
        color: #4F5655;
    }

    // &_one {
    //     width: 100%;
    //     align-items: center;
    //     justify-content: center;
    //     margin: auto;
    //     &_img {
    //         width: 120px;
    //         height: 120px;
    //         margin-bottom: 40px;
    //     }
    //     &_txtHead {
    //         font-weight: 700;
    //         font-size: 16px;
    //         // line-height: 150%;
    //         text-align: center;
    //         color: #040E0C;
    //     }
    //     &_txtSub {
    //         font-weight: 400;
    //         font-size: 14px;
    //         // line-height: 150%;
    //         text-align: center;
    //         color: #4F5655;
    //     }
    // }

    
}

// .failedContainer1 {
//     min-height: 500px;
//     max-height: fit-content;
//     padding: 100px 0;
//     margin: auto;
//     align-items: center;
//     &_img {
//         width: 120px;
//         height: 120px;
//         margin-bottom: 40px;
//     }
//     &_txtHead {
//         font-weight: 700;
//         font-size: 16px;
//         // line-height: 150%;
//         // text-align: center;
//         color: #040E0C;
//     }
//     &_txtSub {
//         font-weight: 400;
//         font-size: 14px;
//         // line-height: 150%;
//         // text-align: center;
//         color: #4F5655;
//     }
// }

.failedContainer1 {
    // min-height: 400px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    // &_img {

    //     // width: 120px;
    //     // height: 120px;
    //     // margin-bottom: 40px;
    // }
    &_txtHead {
        font-weight: 700;
        font-size: 16px;
        // line-height: 150%;
        text-align: center;
        color: #040E0C;
    }
    &_txtSub {
        font-weight: 400;
        font-size: 14px;
        // line-height: 150%;
        // text-align: center;
        color: #4F5655;
    }
}

@primary-color: #278977;