@import "react-responsive-carousel/lib/styles/carousel.min.css";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tourCard {
  margin: 0em;
  animation: fadeIn 0.3s;
}

.tourCard:after {
  position: absolute;
  width: 2em;
  height: 2em;
  bottom: 100%;
  left: 35%;
  margin-left: -2.5em;
  content: "";
  transform: rotate(45deg);
  margin-bottom: -1.5em;
  background-color: #fff;
  z-index: -1;
}

.tourCardQuery {
  animation-duration: 0.3s;
}

.tourCardQuery:after {
  left: 55%;
}

.tourCard > div:last-child {
  margin-top: 1.5em;
}

.tourCard > div:last-child > button:first-child {
  display: none;
}

.tourCard > div:last-child > button:last-child {
  margin: 0 0;
}

@media screen and (max-width: 768px) {
  .tourCard:after {
    left: 75%;
  }

  .tourCardQuery:after {
    left: 55%;
  }
}

.spaceBetween {
  width: 20em;
  display: flex;
  justify-content: space-between;
}

.tourButton {
  height: 2.5em;
  min-width: 7.5em;
  background-color: #1d2624;
  color: #fff;
}

.tourButton:hover {
  background-color: #000;
  color: #fff;
  border-color: inherit;
  transition: 1s;
}

.skipButton {
  width: 5em;
  text-align: left;
  border: none;
  background: none;
  font-weight: bold;
  font-size: 1.2em;
  padding: 0;
  color: #000;
  cursor: pointer;
}
.skipButton:hover {
  color: rgb(39, 137, 119);
}

.mask {
  opacity: 0.7;
}

.maskQuery {
  opacity: 0.7;
}

.remove {
  display: none;
}

.EnterEmail {
  padding-bottom: 8px;
}

.EmailID {
  padding-bottom: 8px;
}

.exporterName {
  font-family: "Gilroy";
  margin: 0;
  font-size: x-large;
}

.emailbtn {
  width: 140px;
}

.sendquery {
  background-color: #1d2624;

  color: #fff;
}

.sendquery:hover {
  background-color: #040e0c;
  color: #fff;
}

.sendquery:disabled {
  background-color: #cdcfce !important;
  color: white !important;
}

.viewbtn {
  font-style: bolder;
  font-weight: bolder;
  color: #0b7764;

  font-size: 16px;
  cursor: pointer;
  float: right !important;
  justify-content: center;
  text-align: center;
}

.carousel .control-dots .dot {
  opacity: 0.6;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 90%);
}

.product-card {
  overflow: hidden;
}
.corouselimg {
  object-fit: cover;
}

.bg-image {
  /* The image used */

  /* Add the blur effect */
  filter: blur(16px);
  -webkit-filter: blur(16px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* .card-image img{
  width: 100%;
  transition: 0.5s all ease-in-out;
}
.card-image:hover img{
  transform: scale(1.5); 
} */
/* .modal-email {
  position: fixed;
  width: 30vw !important;

  left: 35vw !important;
  top: 14em !important;
} */

/* .product-preview-modal {
  width: 100% !important;
} */

@media only screen and (max-width: 800px) {
  .exporterName {
    font-family: "Gilroy";
    margin: 0;
    font-size: large;
  }
  /* .modal-email {
    position: absolute !important;
    width: 100% !important;
    left: 0px !important;
    margin: 0.5em !important;
    top: 10em !important;
  } */
}


.infinite-scroll-component {
  overflow-x: hidden !important;
}
@media only screen and (max-width: 347) {
  .exporterName {
    font-size: 16px;
  }
}

.stickyHeader {
  height: 4.6em;
  /* margin-top: 0.8em !important; */
  padding: 0 50px 0 25px;
}

.stickyHeader.noPadding {
  padding: 0px;
}

.maindiv {
  padding-left: 25px !important;
  padding-right: 3em !important;
}

@media only screen and (max-width: 1000px) {
  .stickyHeader {
    height: 100% !important;
  }
}

/* Media for mobile devices */
@media only screen and (max-width: 420px) {
  .product-preview-wrapper {
    max-height: 900px;
    overflow-y: scroll;
  }

  .stickyHeader {
    padding: 0px;
  }

  .catalogue-product-details {
    max-height: calc(80vh - 400px);
    overflow-y: scroll;
    padding: 20px 10px;
  }

  .product-image-section {
    height: 25em;
    overflow: hidden;
  }

  .product-preview-button-wrapper {
    display: none;
  }
}

/* Media for Iphone 12 */
@media only screen and (device-width: 428px) and (device-height: 926px) {
  .product-preview-wrapper {
    max-height: 900px;
    overflow-y: scroll;
  }

  .catalogue-product-details {
    max-height: calc(80vh - 480px);
    overflow-y: scroll;
    padding: 20px 10px;
  }

  .product-image-section {
    height: 30em;
    overflow: hidden;
  }

  .product-preview-button-wrapper {
    display: none;
  }
}

/* Media for tablets */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .product-preview-wrapper {
    height: 1000px;
    overflow-y: scroll;
  }

  .product-image-section {
    height: 42em;
    overflow: hidden;
  }

  .catalogue-product-details {
    max-height: calc(80vh - 672px);
    overflow-y: scroll;
    padding: 20px 10px;
  }

  .product-preview-button-wrapper {
    position: absolute;
    z-index: 100;
    bottom: -70px;
    left: 42%;
    margin: 0 auto;
    background: white;
    border-radius: 40px;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 60px;
  }
}

.downloadBtn {
  background: none;
  color: #4F5655;
}

.downloadBtn h1 {
  color: #4F5655;
  font-size: 14px;
  font-weight: bold;
}

.collectionName {
  font-size: 18px;
  font-weight: 700;
  color: #040E0C;
  text-transform: capitalize;
}
.firstRow {
  margin-top: 20px;
  padding-left: 10px;
}

.gridView {
  padding: 10px;
  border: 1px solid #B4B7B6;
  width: 3rem;
  justify-content: center;
  border-radius: 5px;
  background-color: #d3d3d373;
  opacity: 0.5;
}

.selected {
  background-color: white;
  opacity: 1;
}

.double {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.single {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.single img {
  padding-left: 5px;
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .catalogue-product-details {
    max-height: 500px;
    overflow-y: scroll;
    padding: 20px 10px;
  }

  .product-preview-button-wrapper {
    position: absolute;
    z-index: 100;
    bottom: -70px;
    left: 42%;
    margin: 0 auto;
    background: white;
    border-radius: 40px;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 60px;
  }
}

.sw-drawer .ant-drawer-header {
  border-bottom: none;
}
.sw-drawer .ant-drawer-title {
  font-weight: bold;
  border-bottom: none;
  font-size: 18px;
}

.sw-drawer .ant-collapse-item{
  border: 1px solid #d9d9d9;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 20px 10px 0px 10px;
}

.sw-drawer .ant-collapse-header {
  font-size: 16px;
}
.sw-drawer .ant-drawer-body{
  margin-bottom: 85px;
}

.clearAll {
  position: absolute;
  top: 16px;
  right: 24px;
  color: #ff3e6c;
}

.drawerBottom {
  position: absolute;
  bottom: 15px;
  width: 90%;
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
}
.drawerCta {
  height: 40px;
}
.sw-drawer .ant-collapse-item .ant-collapse-header{
  border-bottom: none;
  padding: 0px 10px 20px 10px;
}

.sw-drawer .ant-collapse-item .ant-collapse-content .ant-row{
  border-bottom: 1px solid #d9d9d9;
  padding: 20px 0px;
}

.sw-drawer .ant-collapse-item .ant-collapse-content .ant-row:first-child{
  border-top : 1px solid #d9d9d9;
  padding: 20px 0px 20px 0px;
}
.sw-drawer .ant-collapse-borderless .ant-collapse-item:last-child{
  border-radius: 5px !important;
}

.sw-drawer .ant-collapse-item:last-child {
  border-bottom: 1px solid #d9d9d9 !important;
}
.sw-drawer .ant-collapse-item .ant-collapse-content .ant-row:last-child{
  padding: 20px 0px 0px 0px;
  border-bottom: none;
  /* border-radius: 5px !important; */
}

.dataProducts {
  width: 100%;
}

.dataContainer {
  width: 100%;
}
.wishlistIcon {
  margin-right: 5px;
}

.getQuote {
  background: rgb(240, 248, 246);
  color: rgb(11, 119, 100);
  border: 1px solid rgb(109, 173, 162);
  right: 10px;
  position: absolute;
  display: none;
}

.mobile {
  display: block;
  border: none;
  background: none;
}

.productCard {
  padding: 0 10px;
}

.productCard.rowEnd {
  padding-right: 0;
}

.infiniteScroll .product-card:nth-child(4n) {
  padding-right: 0px;
}

.productCard:hover .getQuote {
  display: block;
}

.quickFilters {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  margin-left: 10px;
}

.filterBtn {
  margin-right: 10px;
  background-color: #C9E4DF !important;
  height: 40px;
}

.filterBtn.selectedFilter {
  background-color: #0B7764 !important;
  color: white;
}

.closeIconBanner {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 99;
  /* background: white; */
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

.emptyTitle{
  font-size: 18 px !important;
}

.emptyTitle .ant-typography .mobile{
  font-size: 16 px;
}